import React from "react";
import { cn } from "src/components";

const ShimmerButton = React.forwardRef(({
  shimmerColor,
  shimmerSize = "0.05em",
  shimmerDuration = "3s",
  borderRadius = "6px",
  background,
  className,
  children,
  mode = "dark", // New prop to handle light/dark mode
  ...props
}, ref) => {
  // Adjust colors based on mode
  const getColors = () => {
    if (mode === "light") {
      return {
        shimmerColor: shimmerColor || "#000000",
        background: background || "rgba(255, 255, 255, 1)",
        textColor: "text-black",
        borderColor: "border-black/10",
      };
    } else {
      return {
        shimmerColor: shimmerColor || "#ffffff",
        background: background || "rgba(0, 0, 0, 1)",
        textColor: "text-white",
        borderColor: "border-white/10",
      };
    }
  };

  const { shimmerColor: color, background: bg, textColor, borderColor } = getColors();

  return (
    <button
      style={{
        "--spread": "90deg",
        "--shimmer-color": color,
        "--radius": borderRadius,
        "--speed": shimmerDuration,
        "--cut": shimmerSize,
        "--bg": bg
      }}
      className={cn(
        "group relative z-0 flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap px-6 py-3",
        `${borderColor} ${textColor}`,
        "[background:var(--bg)] [border-radius:var(--radius)]",
        "transform-gpu transition-transform duration-300 ease-in-out active:translate-y-[1px]",
        className
      )}
      ref={ref}
      {...props}
    >
      {/* Spark container */}
      <div className="absolute inset-0 overflow-visible [container-type:size] -z-30 blur-[2px]">
        <div className="absolute inset-0 h-[100cqh] animate-slide [aspect-ratio:1] [border-radius:0] [mask:none]">
          <div className="animate-spin-around absolute inset-[-100%] w-auto rotate-0 [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,var(--shimmer-color)_var(--spread),transparent_var(--spread))] [translate:0_0]" />
        </div>
      </div>
      {children}
      {/* Highlight */}
      <div className={cn(
        "absolute inset-0 h-full w-full",
        "rounded-[6px] px-4 py-1.5 text-sm font-medium",
        mode === "light" 
          ? "shadow-[inset_0_-8px_10px_#0000000a]"
          : "shadow-[inset_0_-8px_10px_#ffffff1f]",
        "transform-gpu transition-all duration-300 ease-in-out",
        mode === "light"
          ? "group-hover:shadow-[inset_0_-6px_10px_#00000014] group-active:shadow-[inset_0_-10px_10px_#00000014]"
          : "group-hover:shadow-[inset_0_-6px_10px_#ffffff3f] group-active:shadow-[inset_0_-10px_10px_#ffffff3f]"
      )} />
      {/* Backdrop */}
      <div className="absolute -z-20 [background:var(--bg)] [border-radius:var(--radius)] [inset:var(--cut)]" />
    </button>
  );
});

ShimmerButton.displayName = "ShimmerButton";

export default ShimmerButton;