import React, { useState, useEffect, useRef } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Icon } from '@iconify/react';

const formatTitle = key => {
  return key
    .split(/(?=[A-Z])/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CACHE_KEY = 'navbarData'

const useNavbarData = apiUrl => {
  const [navbarData, setNavbarData] = useState(() => {
    if (typeof window !== 'undefined') {
      const cachedData = localStorage.getItem(CACHE_KEY)
      return cachedData ? JSON.parse(cachedData) : null
    }
    return null
  })
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    useEffect(() => {
    const fetchNavbarData = async () => {
      try {
        const response = await fetch(`${apiUrl}/navbar`)
        if (!response.ok) throw new Error('Network response was not ok')

        const newData = await response.json()
        setNavbarData(newData)
        setLoading(false);

        if (typeof window !== 'undefined') {
          localStorage.setItem(CACHE_KEY, JSON.stringify(newData))
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchNavbarData()
  }, [apiUrl])

  return { navbarData, loading, error };
};

const NavItem = ({ item, onClick, customClasses = {}, mode }) => (
  <div className={`group relative flex gap-x-6 rounded-lg p-4 ${mode === 'light' ? 'hover:bg-neutral-100' : 'hover:bg-[#131315]'} ${customClasses.container || ''}`}>
    <div
      className={`mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg ${
        mode === 'light' ? 'group-hover:bg-white' : 'group-hover:bg-[#2470FF]'
      } ${customClasses.iconContainer || ''}`}
    >
      <Icon
        icon={item.icon}
        className={`text-[25px] ${
          mode === 'light'
            ? 'text-neutral-500 group-hover:text-black'
            : 'text-neutral-500 group-hover:text-white'
        } ${customClasses.icon || ''}`}
      />
    </div>
    <div>
      <a
        onClick={() => onClick(item.href)}
        className={`font-semibold ${
          mode === 'light'
            ? 'text-neutral-900 group-hover:text-black'
            : 'text-white group-hover:text-white'
        } cursor-pointer ${customClasses.link || ''}`}
      >
        {item.name}
        <span className='absolute inset-0' />
      </a>
      <p className={`mt-1 ${mode === 'light' ? 'text-neutral-500' : 'text-neutral-400'} ${customClasses.description || ''}`}>{item.description}</p>
    </div>
  </div>
);

const StakeItem = ({ item, onClick, customClasses = {}, mode }) => (
  <div
    className={`${mode === 'light' ? 'bg-neutral-100' : 'bg-[#131315]'} px-8 py-6 cursor-pointer ${customClasses.container || ''}`}
    onClick={() => onClick(item.href)}
  >
    <div className='flex items-center gap-x-3'>
      <h3 className={`text-sm font-semibold leading-6 ${mode === 'light' ? 'text-neutral-800' : 'text-neutral-300'} ${customClasses.title || ''}`}>{item.name}</h3>
      <p
        className={`rounded-full ${
          mode === 'light' ? 'bg-neutral-200 text-neutral-800' : 'bg-neutral-500/10 text-white'
        } px-2.5 py-1.5 text-xs font-semibold ${customClasses.newBadge || ''}`}
      >
        New
      </p>
    </div>
    <p className={`mt-2 text-sm leading-6 ${mode === 'light' ? 'text-neutral-600' : 'text-neutral-500'} ${customClasses.description || ''}`}>{item.description}</p>
  </div>
);

const NavSection = ({ title, items, onItemClick, customClasses = {}, mode }) => {
  if (!items || items.length === 0) {
    return null;
  }

  const regularItems = title === 'Trading' ? items.slice(0, -1) : items;
  const stakeItem = title === 'Trading' ? items[items.length - 1] : null;

  return (
    <Popover className='relative'>
      <PopoverButton
        className={`inline-flex items-center whitespace-nowrap gap-x-1 text-[12px] sm:text-[12px] md:text-[15px] 2xl:text-sm font-semibold leading-6 text-white hover:text-[#5792FF] focus:outline-none focus:ring-0 focus:border-none active:ring-0 active:border-none ${
          customClasses.button || ''
        }`}
      >
        <span>{title}</span>
        <ChevronDownIcon aria-hidden='true' className='h-3 w-3 sm:h-3.5 sm:w-3.5 md:h-4 md:w-4' />
      </PopoverButton>
      <PopoverPanel
        className={`absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in ${customClasses.panel || ''}`}
      >
        <div
          className={`w-screen max-w-md flex-auto overflow-hidden rounded-b-3xl rounded-t-3xl ${
            mode === 'light' ? 'bg-white' : 'bg-[#0E0E10]'
          } text-sm leading-6 shadow-lg ring-1 ${
            mode === 'light' ? 'ring-neutral-200' : 'ring-[#27272A]'
          } lg:max-w-3xl ${customClasses.panelContent || ''}`}
        >
          <div className={`grid grid-cols-1 max-h-[600px] overflow-y-auto gap-x-6 gap-y-1 p-4 lg:grid-cols-2 ${customClasses.itemsGrid || ''}`}>
            {regularItems.map(item => (
              <NavItem key={item.name} item={item} onClick={onItemClick} customClasses={customClasses.navItem} mode={mode} />
            ))}
          </div>
          {stakeItem && <StakeItem item={stakeItem} onClick={onItemClick} customClasses={customClasses.stakeItem} mode={mode} />}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export const NavMenu = ({ apiUrl, customClasses = {}, mode }) => {
  const { navbarData, loading, error } = useNavbarData(apiUrl);

  const handleItemClick = (href, title) => {
    console.log('href, title', href, title);

    if (title === 'Market Data' || href === '/portfolio') {
      const baseUrl = 'https://sharpe.ai/';
      window.open(`${baseUrl}${href}`, '_self');
    } else if (title === 'Community') {
      window.open(href, '_blank'); 
    } else {
      window.open(href, '_self');
    }
  };

  return (
    <div className={`hidden lg:flex flex-1 justify-center lg:gap-x-6 xl:gap-x-12 2xl:gap-x-14 ${customClasses.container || ''}`}>
      {navbarData &&
        Object.entries(navbarData).map(([key, items]) => (
          <NavSection
            key={key}
            title={formatTitle(key)}
            items={items}
            onItemClick={href => handleItemClick(href, formatTitle(key))}
            customClasses={customClasses.navSection}
            mode={mode}
          />
        ))}
    </div>
  );
};